<template>
  <TourTooltip
    :steps="steps"
    @next="nextStep($event)"
    @previous="previousStep($event)"
    @skip="$emit('skip')"
  />
</template>

<script>
import TourTooltip from '@/components/TourTooltip'

export default {
  name: 'TourAssessmentResults',

  components: {
    TourTooltip,
  },

  props: {
    application: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      steps: [
        {
          target: '#v-step-3',
          content: `
            <h4 class="v-step__title">Aqui ficam os resultados das provas</h4>
            <span class="v-step__body">
              Ao finalizar uma prova você fica sabendo por aqui qual a sua nota e quantas questões acertou.
            </span>`,
          params: {
            placement: 'top',
          },
        },
      ],
    }
  },

  methods: {
    nextStep(tour) {
      if (tour.currentStep === tour.steps.length - 1) {
        this.$router.push({
          name: 'home',
        })
        tour.stop()
        this.$emit('finish')
      } else {
        tour.nextStep()
      }
    },

    previousStep(tour) {
      if (tour.currentStep === 0) {
        this.$router.push({
          name: 'assessmentExams',
          params: {
            assessmentId: this.application.assessmentId,
            applicationId: this.application.id,
          },
        })
        this.$emit('previous')
      } else {
        tour.previousStep()
      }
    },
  },
}
</script>
